/* eslint-disable */

<template>
  <div>
    <!-- <QrCodeScanner /> -->

    <div class="row">
      <div class="col-sm-12">
        <div>
          <div class="top_div">
            <h5 class="mb-2"><b>Détecteur de gamme A320</b></h5>

            <div v-if="site_status == false">
              Hum, l'application n'est pas connectée à un magasin, avez vous
              utilisé le bon QR code?
            </div>

            <div v-if="site_status == true">
              <div class="mb-2">
                Vous êtes connecté au site {{ site_label }}
              </div>

              <div v-if="offline">
                <!-- Les données du magasin sont chargées en mémoire. -->
                <b-button block variant="outline-warning" size="sm" class="mb-2">Les données sont chargées
                  offline</b-button>
              </div>

              <div class="accordion" role="tablist" v-if="offline == false">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="outline-primary" size="sm">Mode 'online' par
                      défaut</b-button>
                  </b-card-header>

                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <div v-if="offline == false">
                          <p>
                            L'application est optimisée pour fonctionner avec
                            une connection à internet
                          </p>
                          <p>
                            Il est possible d'activer un mode "offline" pour les
                            magasins sans 4G mais ceci est
                            <u>consommateur de bande passante</u>
                          </p>
                          <p>
                            Nous vous recommandons de n'utliser le mode
                            "offline" qu'en cas de besoin
                          </p>
                          <b-button size="sm" variant="danger" class="mb-6" @click="getOfflineData(hash_site)">J'ai
                            compris mais je veux charger les données
                            offline</b-button>

                          <div class="d-flex justify-content-center mb-3 mt-6" v-if="loading">
                            <b-spinner label="Loading..." class="mt-4" type="grow"></b-spinner>
                          </div>
                        </div>

                        <!-- <b-form-checkbox v-model="offline" name="check-button" switch>
          Mode <b><span v-if="offline">Offline</span><span v-else>Online</span></b>
        </b-form-checkbox>   -->
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <div class="middle_div">
            <div>
              <div v-if="scan_mode == null">
                <HTMLscanner :qrbox="250" :fps="10" style="width: 100%" @result="predecode" v-if="showQR" />
              </div>

              <b-input-group id="claviernum" class="mt-2">
                <b-input-group-prepend>
                  <b-input-group-text>Saisie manuelle</b-input-group-text>
                  <!-- <b-button variant="outline-info">Saisie manuelle</b-button> -->
                </b-input-group-prepend>

                <b-form-input type="number" pattern="[0-9]*" size="30" min="8" max="13"
                  v-model="manual_ean"></b-form-input>

                <b-input-group-append>
                  <b-button variant="info" @click="reset_manual_ean">Reset</b-button>
                </b-input-group-append>
              </b-input-group>

              <!-- <v-quagga :onDetected="onDecode" :readerSize="readerSize" :readerTypes="['ean_reader']" :aspectRatio="aspectRation"></v-quagga> -->
              <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader> -->
            </div>
            <div class="bottom_div mt-1">
              <div v-if="EAN != ''" class="detected_barcode">
                Code barre détecté : {{ EAN }}
              </div>
              <div class="mt-2" v-html="html_box" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import { StreamBarcodeReader } from 'vue-barcode-reader';
// import { NumericInput } from 'numeric-keyboard'
// import QrCodeScanner from './QrCodeScanner';
import HTMLscanner from './HTML5.vue';
var Barcoder = require('barcoder');
// import Vue from 'vue';
// import VueQuagga from './quagga.vue';
// Vue.use(VueQuagga);

// import StreamBarcodeReader from './StreamBarcodeReader';

/* eslint operator-linebreak: [2, "after", { "overrides": { "?": "ignore", ":": "ignore"} }] */
export default {
  data() {
    return {
      manual_ean: '',
      aspectRation: {
        min: 16 / 9,
        max: 16 / 9,
      },
      date: '',
      showQR: false,
      site_label: '',
      code_site: '',
      site_status: false,
      scope: '',
      offline: false,
      hash_site: '',
      scan_mode: null,
      EAN: '',
      html_box: '',
      product_label: '',
      scanner: true,
      image_loader: false,
      loading: false,
      /* products: [], */
      message: '',
      readerSize: {
        width: 640,
        height: 480,
      },
      results: [],
      offline_data: {
        catalogue: [],
        chainages: [],
        code_site: '',
        site_format: '',
        lib_produits: '',
        liste_ean_a_chainer: [],
        liste_me_too_auchan: [],
        site_label: '',
        EAN_NAL_MN_A_CHAINER: [],
        EAN_NAL_MDD_A_CHAINER: [],
      },
    };
  },
  watch: {
    manual_ean: function (val, old_val) {
      console.log(val);
      
      this.html_box = '';
      this.EAN = '';
      if (val.length == 13 ) {
        this.onDecode(val.toString());
      }

    },
  },
  components: {
    // StreamBarcodeReader,
    // ImageBarcodeReader,
    // QrCodeScanner,
    HTMLscanner,
  },
  computed: {
    products() {
      // return this.$store.getters.allProducts;
    },
    stores() {
      // return this.$store.getters.allStores;
    },
  },
  methods: {
    reset_manual_ean() {
      this.manual_ean = '';
      this.html_box = '';
    },
    predecode(decodedText) {
      this.results.push(decodedText);
      console.log(this.results);

      var arr = this.results;

      if (arr.length < 5) {
        return null; // Return null if the array is empty
      }

      // Take the last 5 elements, or fewer if the array has less than 5 elements
      const lastFive = arr.slice(-5);

      // Count the occurrences of each element
      const counts = {};
      lastFive.forEach((item) => {
        counts[item] = (counts[item] || 0) + 1;
      });

      // Determine the most frequent element
      let mostFrequent = lastFive[0];
      let maxCount = counts[mostFrequent];

      for (const [item, count] of Object.entries(counts)) {
        if (count > maxCount) {
          mostFrequent = item;
          maxCount = count;
        }
      }
      this.results = [];
      this.onDecode(mostFrequent);
    },
    onDecode(decodedText) {
      // decodedText = decodedText['codeResult']['code']
      // handle the message here :)
      /* eslint-disable no-alert, no-console */
      var validBarCode = Barcoder.validate(decodedText);

      if (decodedText != this.EAN && validBarCode) {

        console.log('detected', decodedText);
        if (decodedText != this.manual_ean) {
          this.manual_ean = '';
        }
        // this.EAN = decodedText;
        this.EAN = decodedText.padStart(13, '0');

        // Reset scanner
        this.scanner = false;
        this.scanner = true;
        this.manual_ean = '';
        if (this.offline == false) {
          this.getProductInfo(decodedText, this.hash_site);
        } else {
          this.offline_getProductInfo(this.EAN, this.offline_data.code_site);
        }
      }

      /* eslint-enable no-alert, no-console */
    },
    onLoaded() {
      // handle the message here :)
      /* eslint-disable no-alert, no-console */

      console.log('loaded');

      /* eslint-enable no-alert, no-console */
    },

    getSiteInfo(hash_site) {
      const path = `/api/site?hash_site=${hash_site}`;
      axios
        .get(path)
        .then((res) => {
          console.log(res.data);
          this.site_label = res.data.site_label;
          this.code_site = res.data.code_site;
          this.scope = res.data.scope;

          if (res.data.code_site != '') {
            this.site_status = true;
            this.showQR = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    getProductInfo(ean, hash_site) {
      const path = `/api/ean?hash_site=${hash_site}&ean=${ean}`;
      axios
        .get(path)
        .then((res) => {
          this.html_box = res.data;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 100);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getOfflineData(hash_site) {
      this.loading = true;

      const path = `/api/offlinedata?hash_site=${hash_site}`;
      axios
        .get(path)
        .then((res) => {
          this.offline_data = res.data;
          this.loading = false;
          this.offline = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    offline_getProductInfo(ean, site) {
      const inCatalogue = this.offline_data.catalogue.filter(
        (item) => item.CODE_SITE === site && item.EAN === ean,
      );
      const productInfo = this.offline_data.lib_produits.filter(
        (item) => item.EAN === ean,
      );
      const me_too_casino = this.offline_data.chainages.filter(
        (item) => item['EAN AUCHAN'] === ean,
      );
      const me_too_auchan = this.offline_data.chainages.filter(
        (item) => item['EAN CASINO'] === ean,
      );

      var info_remise = '';
      var scope = this.scope;
      var me_too_casino_str = '';

      var me_too_auchan_str = '';
      var infoNomenclatureMeTooAuchan = '';
      if (me_too_auchan.length > 0 && scope == "A320") {
        me_too_auchan_str =
          'Chainé avec le produit Auchan ' + me_too_auchan[0]['EAN AUCHAN'];


        const productInfoMeToo = this.offline_data.lib_produits.filter(
          (item) => item.EAN === me_too_auchan[0]['EAN AUCHAN'],
        );

        if (productInfoMeToo.length > 0) {
          infoNomenclatureMeTooAuchan = '<i>';
          const nomenclatureFields = [
            'lib_enc_lvl1',
            'lib_enc_lvl2',
            'lib_enc_lvl3',
            'lib_enc_lvl4',
            'lib_enc_lvl5',
          ];
          nomenclatureFields.forEach((field) => {
            if (productInfoMeToo[0][field] !== '') {
              infoNomenclatureMeTooAuchan += ' > ' + productInfoMeToo[0][field];
            }
          });
          infoNomenclatureMeTooAuchan += '</i>';
          console.log(infoNomenclatureMeTooAuchan);

          if (scope != 'A320') {
            me_too_auchan_str = "";
            infoNomenclatureMeTooAuchan = ""
          }
        }
      }

      console.log('CATALOG INFO');
      console.log(inCatalogue);
      console.log('PRODUCT INFO');
      console.log(productInfo);

      let htmlString = ''; // Initialize the string that will be injected into HTML

      if (productInfo.length > 0) {
        var infoNomenclature = '<i>';
        const nomenclatureFields = [
          'lib_enc_lvl1',
          'lib_enc_lvl2',
          'lib_enc_lvl3',
          'lib_enc_lvl4',
          'lib_enc_lvl5',
        ];
        nomenclatureFields.forEach((field) => {
          if (productInfo[0][field] !== '') {
            infoNomenclature += ' > ' + productInfo[0][field];
          }
        });
        infoNomenclature += '</i>';
      }

      // Si le produit appartient à la gamme du magasin?
      if (inCatalogue.length > 0) {
        const label = productInfo[0].LIB_EAN;

        var appartenance_gamme = '<h3 class="en_gamme">EN GAMME MAGASIN</h3>';

        htmlString += `<div class="en_gamme"><h3>EN GAMME MAGASIN</h3></div><div>${infoNomenclature}<p>${ean} : ${label}</p></div>`;

        htmlString = `
            ${appartenance_gamme}
            <div>${infoNomenclature}</div>
            <div class="mt-1">Article : <span class="sku_label">${productInfo[0].LIB_EAN}</span></div>
            <div>${me_too_casino_str}</div>
        `;
      } 
      // si le produit est hors gamme on vérifie le chainage et que le site n'est pas un AF17
      else if (this.offline_data.liste_ean_a_chainer.includes(ean) && scope == "A320") {
        const chainageInfo = this.offline_data.chainages.find(
          (item) => item['EAN CASINO'] === ean,
        );
        const eanMeTooAuchan = chainageInfo ? chainageInfo['EAN AUCHAN'] : '';

        const meTooPresenceCatalog = this.offline_data.catalogue.filter(
          (item) => item.CODE_SITE === site && item.EAN === eanMeTooAuchan,
        );
        const meTooProductInfo = this.offline_data.lib_produits.filter(
          (item) => item.EAN === eanMeTooAuchan,
        );

        var appartenance_gamme =
          '<h3 class="chainage">HORS GAMME MAIS A IMPLANTER EN RAYON</h3>';
        // Chainage uniquement si produit dans catalogue magasin
        if (meTooPresenceCatalog.length > 0) {
          htmlString = `
            ${appartenance_gamme}
            <div>${me_too_auchan_str}</div>
            <div class="mt-2 nomenclature">${infoNomenclatureMeTooAuchan}</div>
        `;
        } else {
          //htmlString = `<div class="inconnu"><h3>Article sans infos</h3></div><div class="ean_solo"><p>${ean}</p></div>`;
          htmlString = `<div class="inconnu"><h3>Article hors gamme & non chaîné sur site</h3></div><div class="info_inconnu">anciennement affiché comme "Article sans infos"</div><div><p>${ean}</p></div>`;

          var info_remise =
            '<div class="remise_no mt-2">🚫 Pas de remise. A implanter en FDR dans la mesure du possible</div>';
          if (this.offline_data.EAN_REMISE_WAAOH.includes(ean)) {
            info_remise =
              '<div class="remise_waaoh mt-2">⚡️ Cagnotte -50% sur le 2 ème</div>';
          }
          if (this.offline_data.EAN_REMISE_DECISION_MAG.includes(ean)) {
            info_remise =
              '<div class="remise_arbitrage_mag mt-2">⚖️ Remise selon arbitrage magasin</div>';
          }
          if (
            this.offline_data.EAN_REMISE_NAL.includes(ean) &&
            this.offline_data.site_format == 'HM'
          ) {
            info_remise =
              '<div class="remise_arbitrage_mag mt-2">⚖️ NAL - Remise selon arbitrage magasin</div>';
          }
          if (
            this.offline_data.EAN_NAL_MDD_A_CHAINER.includes(ean) &&
            this.offline_data.site_format == 'HM'
          ) {
            info_remise =
              '<div class="remise_arbitrage_mag mt-2">⚖️ NAL - Remise selon arbitrage magasin</div>';
          }

          htmlString += `<div class="inconnu"><h3>Article hors gamme & non chaîné sur site</h3></div>${info_remise}<div class="info_inconnu">anciennement affiché comme "Article sans infos"</div><div><p>${ean}</p></div>`;
        }
      } else if (
        productInfo.length > 0 &&
        ['282', '266', '270'].includes(site)
        
      ) {
        const label = productInfo[0].LIB_EAN;
        var appartenance_gamme =
          '<h3 class="hors_gamme_mag_gamme_Auchan">HORS GAMME MAG MAIS EN GAMME AUCHAN</h3>';
        var descriptif_article = `<div class="mt-1">Article : <span class="sku_label">${label}</span></div>`;

        htmlString = `
            ${appartenance_gamme}
            <div>${descriptif_article}</div>
            <div class="mt-2 nomenclature">${infoNomenclature}</div>
        `;
      } else if (this.offline_data.EAN_NAL_MN_A_CHAINER.includes(ean) && scope == "A320") {
        var appartenance_gamme =
          '<h3 class="chainage">HORS GAMME MAIS FOURNISSEUR NAL CONNU A IMPLANTER EN RAYON</h3>';
        htmlString = `
            ${appartenance_gamme}
        `;
      } else if (this.offline_data.EAN_NAL_MDD_A_CHAINER.includes(ean) && this.offline_data.site_format == "SM" && scope == "A320") {
        var appartenance_gamme =
          '<h3 class="chainage">HORS GAMME MAIS MDD NAL CASINO A IMPLANTER EN RAYON</h3>';
        htmlString = `
            ${appartenance_gamme}
        `;
      } else {
        
        var info_remise =
          '<div class="remise_no mt-2">🚫 Pas de remise. A implanter en FDR dans la mesure du possible</div>';


        if (this.offline_data.EAN_REMISE_WAAOH.includes(ean) && scope == "A320") {
          info_remise =
            '<div class="remise_waaoh mt-2">⚡️ Cagnotte -50% sur le 2 ème</div>';
        }
        if (this.offline_data.EAN_REMISE_DECISION_MAG.includes(ean) && scope == "A320") {
          info_remise =
            '<div class="remise_arbitrage_mag mt-2">⚖️ Remise selon arbitrage magasin</div>';
        }
        if (
          this.offline_data.EAN_REMISE_NAL.includes(ean) &&
          this.offline_data.site_format == 'HM' && scope == "A320"
        ) {
          info_remise =
            '<div class="remise_arbitrage_mag mt-2">⚖️ NAL - Remise selon arbitrage magasin</div>';
        }

        if (
          this.offline_data.EAN_NAL_MDD_A_CHAINER.includes(ean) &&
          this.offline_data.site_format == 'HM' && scope == "A320"){
          info_remise =
            '<div class="remise_arbitrage_mag mt-2">⚖️ NAL - Remise selon arbitrage magasin</div>';
        }



        if (scope != "A320") {
          info_remise = ""
        }



        htmlString += `<div class="inconnu"><h3>Article hors gamme & non chaîné sur site</h3></div>${info_remise}<div><p>${ean}</p></div>`;
        if (this.scope != "A320") {
          info_remise = ""
          htmlString = `<div class="inconnu"><h3>Article hors gamme magasin</h3></div><div><p>${ean}</p></div>`;
        }

        
      }
      console.log(htmlString);
      this.html_box = htmlString;
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    },
  },

  created() {
    /* this.getStores(); */
    // this.$store.dispatch('getProducts');
    // this.$store.dispatch('getStores');

    // const now = new Date();
    // const today = new Date(
    //   now.getFullYear(),
    //   now.getMonth(),
    //   now.getDate(),
    //   now.getHours(),
    //   now.getMinutes(),
    // );
    // this.date = today;
    // this.addProductForm.date = today;

    const queryString = window.location.search;
    console.log(queryString);

    const urlParams = new URLSearchParams(queryString);
    const hash_site = urlParams.get('hash_site');
    this.hash_site = hash_site;
    this.getSiteInfo(hash_site);
    const scan_mode = urlParams.get('scan_mode');
    this.scan_mode = scan_mode;
    console.log(scan_mode);
    // ?product=shirt&color=blue&newuser&size=m
  },
};
</script>
<style>
div {
  font-size: 14px;
}

.hors_gamme {
  border-radius: 20px;
  background-color: red;
  color: white;
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.chainage {
  border-radius: 20px;
  background-color: yellow;
  color: black;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.en_gamme {
  border-radius: 20px;
  background-color: green;
  color: white;
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.hors_gamme_mag_gamme_Auchan {
  border-radius: 20px;
  background-color: rgba(0, 81, 255, 0.988);
  color: white;
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.inconnu {
  border-radius: 20px;
  background-color: grey;
  color: white;
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.info_inconnu {
  text-align: center;
  font-style: italic;
  margin-bottom: 3px;
}

.remise_waaoh {
  border-radius: 10px;
  background-color: #02ff34;
  color: white;
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
  animation: pulse-animation 2s infinite;
  border: solid 0.1rem rgb(1, 158, 48);
}

.remise_arbitrage_mag {
  border-radius: 10px;
  background-color: #fe7802;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
}

.remise_no {
  border-radius: 10px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
  border: solid 0.1rem black;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.ean_solo {
  text-align: center;
}

.sku_label {
  font-weight: bold;
}

.me_too_casino .me_too_auchan {
  color: coral;
}

.detected_barcode {
  text-align: center;
  color: grey;
  font-size: 10px;
  font-style: italic;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
